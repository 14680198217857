<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-experience">
    <img
      src="/assets/img/kids-experience-main.png"
      alt="無料体験 | 小学生限定 | かけっこ・体幹教室 | 苦手な体育を克服 | さらに運動能力アップ| 足が速くなる"
      class="c-pc"
    />
    <img
      src="/assets/img/kids-experience-main-sp.png"
      alt="無料体験 | 小学生限定 | かけっこ・体幹教室 | 苦手な体育を克服 | さらに運動能力アップ| 足が速くなる"
      class="c-sp"
    />
    <div class="l-experience__main">
      <div class="l-experience__main__service">
        <!-- <img
          src="./../../../../assets/img/new/okasi_tumehoudai.png"
          alt=""
          style="display: flex; margin: 0 auto 32px auto"
        /> -->
        <a
          href="/short-lesson-2024-summer"
          style="display: flex; margin: 0 auto 32px; width: 280px"
        >
          <img
            src="./../../../../assets/img/short-lesson-2024-natu-experience-img.png"
            alt=""
            style="width: 280px"
          />
        </a>

        <div class="l-experience__main__service__overview">
          <h3 class="l-experience__main__service__overview__title">
            ダイナモBスタジオとは?
          </h3>
          <div class="l-experience__main__service__overview__box">
            <p class="l-experience__main__service__overview__box__list">
              運動を行う上で基本となる「身体の使い方」を学ぶ場を提供しています。
            </p>
            <p class="l-experience__main__service__overview__box__list">
              速く走るための「走り方」を指導し、「体幹トレーニング」を通じて、あらゆるスポーツのパフォーマンス向上に寄与するようなプログラムを実施しております。
            </p>
            <p class="l-experience__main__service__overview__box__list">
              また、運動の問題は論理的に解決できるものが多いため、確かな知識と経験による解決方法を提供しております。
            </p>
          </div>
        </div>

        <div class="l-experience__main__service__guidance">
          <h3 class="l-experience__main__service__guidance__title">指導方針</h3>
          <div class="l-experience__main__service__guidance__box">
            <p class="l-experience__main__service__guidance__box__list">
              一般的な学校体育では、一人の教師が30人近くの生徒を指導しています。また、他の運動教室でも多くの生徒が一人の教師によって指導されることが一般的です。
            </p>
            <p class="l-experience__main__service__guidance__box__list">
              当教室では、質の高い指導を実現するために、一クラスを最大10名までに限定しています。
            </p>
            <p class="l-experience__main__service__guidance__box__list">
              これにより、一人ひとりに丁寧な指導を行い、生徒が「できる」と感じる経験を積むことができます。
            </p>
          </div>
        </div>
      </div>
      <img
        src="./../../../../assets/img/new/kids-experience-flow2.png"
        alt="運動ができるようにするためには「ダイナモBスタジオ」"
        class="c-pc"
      />
      <img
        src="./../../../../assets/img/new/kids-experience-flow2-sp.png"
        alt="運動ができるようにするためには「ダイナモBスタジオ」"
        class="c-sp"
      />

      <div class="l-experience__main__service__class">
        <h3 class="l-experience__main__service__class__title">クラス紹介</h3>
        <ul class="l-experience__main__service__class__list">
          <li (click)="onClickSection('section1')">
            <img
              src="./../../../../assets/img/kids-experience-class1.png"
              alt="未就学児|かけっこ・体幹クラス"
            />
          </li>
          <li (click)="onClickSection('section2')">
            <img
              src="./../../../../assets/img/kids-experience-class2.png"
              alt="小学生向け|かけっこ・体幹クラス"
            />
          </li>
          <li (click)="onClickSection('section3')">
            <img
              src="./../../../../assets/img/kids-experience-class3.png"
              alt="小学生・中学生向け|体幹・スポーツクラス"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="l-experience__main__preschooler" id="section1">
      <h3 class="l-experience__main__preschooler__title">
        未就学児向け<br />かけっこ・体幹クラス
      </h3>
      <div class="l-experience__main__preschooler__img">
        <img src="./../../../../assets/img/kids-preschooler-main.png" alt="" />
      </div>
      <div class="l-experience__main__preschooler__text">
        <p class="l-experience__main__preschooler__text__description">
          走るフォームの修正と速さを向上させるための体幹トレーニングに特化したクラスです。4歳から12歳の子どもたちに、
          バランス感覚を養いながら神経系に刺激を与える運動を通じて、運動能力を高めます。これにより、「できない」
          が「できる」へと変わる体験を提供します。
        </p>

        <h3 class="l-experience__main__preschooler__text__title">
          トレーニング効果
        </h3>
        <ul class="l-experience__main__preschooler__text__list">
          <li>
            <img src="./../../../../assets/img/blue_circle.png" alt="" />
            <p>毎週通いたいと思える</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_circle.png" alt="" />
            <p>楽しく継続できる</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_circle.png" alt="" />
            <p>運動能力がアップできる</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_circle.png" alt="" />
            <p>考える力を身につける</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_circle.png" alt="" />
            <p>色んな運動の基礎をトレーニングできる</p>
          </li>
        </ul>
      </div>
      <div class="l-experience__main__preschooler__text__achievements">
        <h3 class="l-experience__main__preschooler__text__achievements__title">
          トレーニング実績
        </h3>
        <ul class="l-experience__main__preschooler__text__achievements__list">
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>ボールの投げ方からとり方が出来た</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>こけない・速い走り方になった</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>運動能力の向上した</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>バランス感覚・体幹が向上した</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>運動が楽しくなり挑戦をしたくなった</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>飛び箱等の恐怖心が無くなった</p>
          </li>
          <li>
            <img src="./../../../../assets/img/blue_checkbox.png" alt="" />
            <p>毎週楽しみの習い事になった</p>
          </li>
        </ul>
      </div>
      <div class="l-experience__main__preschooler__text__method">
        <h3 class="l-experience__main__preschooler__text__method__title">
          小学生のうちに達成してスタートダッシュ！<br />オリジナル目標達成シート
        </h3>
        <div>
          <img
            src="./../../../../assets/img/未就学_sample.png"
            alt=""
            width="100%"
          />
        </div>
      </div>
    </div>

    <div class="l-experience__main__run" id="section2">
      <h3 class="l-experience__main__run__title">
        小学生向け<br />かけっこ・体幹クラス
      </h3>
      <div class="l-experience__main__run__img">
        <img src="./../../../../assets/img/kids-run-main.png" alt="" />
      </div>
      <div class="l-experience__main__run__text">
        <p class="l-experience__main__run__text__description">
          走るフォームの修正と速さを向上させるための体幹トレーニングに特化したクラスです。
          4歳から12歳の子どもたちに、バランス感覚を養いながら神経系に刺激を与える運動を通じて、運動能力を高めます。
          これにより、「できない」 が「できる」へと変わる体験を提供します。
        </p>

        <h3 class="l-experience__main__run__text__title">トレーニング効果</h3>
        <ul class="l-experience__main__run__text__list">
          <li>
            <img src="./../../../../assets/img/green_circle.png" alt="" />
            <p>速く走るコツとトレーニングを実施</p>
          </li>
          <li>
            <img src="./../../../../assets/img/green_circle.png" alt="" />
            <p>体幹トレーニングで姿勢がアップ</p>
          </li>
          <li>
            <img src="./../../../../assets/img/green_circle.png" alt="" />
            <p>「できない」から「できる」ように</p>
          </li>
          <li>
            <img src="./../../../../assets/img/green_circle.png" alt="" />
            <p>三半規管のトレーニングで車酔い防止</p>
          </li>
          <li>
            <img src="./../../../../assets/img/green_circle.png" alt="" />
            <p>身体の柔軟性を向上</p>
          </li>
        </ul>
      </div>
      <div class="l-experience__main__run__text__achievements">
        <h3 class="l-experience__main__run__text__achievements__title">
          タイムの実績
        </h3>
        <p>最大50m走</p>
        <p>6カ月で最短</p>
        <p class="l-experience__main__run__text__achievements__bold">
          10秒台から<span>8秒台</span>の実績
        </p>
        <p>平均すると0.8秒短縮</p>
      </div>

      <div class="l-experience__main__run__text__method">
        <h3 class="l-experience__main__run__text__method__title">
          自分で書くからこそ意味がある<br />目標達成シート
        </h3>
        <div>
          <img
            src="./../../../../assets/img/run_sample.png"
            alt=""
            width="100%"
          />
        </div>
      </div>
    </div>

    <div class="l-experience__main__preschooler" id="section3">
      <h3 class="l-experience__main__preschooler__title">
        高学年・中学生向け<br />体幹・スポーツクラス
      </h3>
      <div class="l-experience__main__preschooler__img">
        <img src="./../../../../assets/img/kids-taikan-main.png" alt="" />
      </div>
      <div class="l-experience__main__preschooler__text">
        <p class="l-experience__main__preschooler__text__description">
          スポーツや体育の授業では、サッカーや
          バレーボールのような球技から、飛び箱や
          鉄棒といった体操まで、多岐にわたる新しい動作を学びます。
          このコースでは、新しいスポーツや体育の動作をスムーズに習得できるようにする
          ためのスキルを身につけていただきます。
        </p>

        <h3 class="l-experience__main__preschooler__text__title">
          トレーニング効果
        </h3>
        <ul class="l-experience__main__preschooler__text__list">
          <li>
            <img src="./../../../../assets/img/orange_circle.png" alt="" />
            <p>バランス感覚を向上</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_circle.png" alt="" />
            <p>体力の向上・メンタル面の強化</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_circle.png" alt="" />
            <p>新しい動きについていく</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_circle.png" alt="" />
            <p>スポーツの考え方を知りたい</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_circle.png" alt="" />
            <p>部活・スポーツで結果を残したい</p>
          </li>
        </ul>
      </div>
      <div class="l-experience__main__preschooler__text__achievements">
        <h3 class="l-experience__main__preschooler__text__achievements__title">
          トレーニング実績
        </h3>
        <ul class="l-experience__main__preschooler__text__achievements__list">
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>高くジャンプする事が出来た</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>高学年以降の不安定なメンタルをケア</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>スポーツの重心移動を修正</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>怪我をしないボールの投げ方になった</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>走るときのフォーム修正された</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>正しい身体の使い方を知れた</p>
          </li>
          <li>
            <img src="./../../../../assets/img/orange_checkbox.png" alt="" />
            <p>スポーツの課題に対して言語化できるようになった</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="l-experience__main__features">
      <h3 class="l-experience__main__features__title">全レッスンの特徴</h3>

      <div class="l-experience__main__features__list">
        <img src="./../../../../assets/img/features1.png" alt="" />
        <img src="./../../../../assets/img/features2.png" alt="" />
        <img src="./../../../../assets/img/features3.png" alt="" />
        <img src="./../../../../assets/img/features4.png" alt="" />
        <img src="./../../../../assets/img/features5.png" alt="" />
        <img src="./../../../../assets/img/features6.png" alt="" />
      </div>
    </div>

    <div class="l-experience__main__service__flow">
      <div class="l-experience__main__service__flow__content1">
        <h2 class="l-experience__main__service__flow__content1__title">
          体験入店の流れ
        </h2>
      </div>
      <div class="l-experience__main__service__flow__content2">
        <img
          src="./../../../../assets/img/new/kids-ninthview1.png"
          alt="電話、Web、LINEにて連絡があり次第、予約を取らせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview2.png"
          alt="簡単な質問用紙に記入をお願いしております。運動できる服装と水分の用意をお願い致します。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview3.png"
          alt="約60分ほどの運動を体験して頂きます。どのような運動の効果があるかスタッフが保護者の方に説明もさせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview4.png"
          alt="スタッフが全員にお声掛けさせていただきます。入会希望の方はこちらで入会手続きをさせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview5.png"
          alt="簡単なアンケートを記入して頂き終了となります。"
        />
      </div>
    </div>

    <div class="l-experience__main__service__flow">
      <div class="l-experience__main__service__flow__content1">
        <h2 class="l-experience__main__service__flow__content1__title">
          お客様の反応
        </h2>
      </div>
      <div class="l-experience__main__service__flow__content1">
        <h2 class="l-experience__main__service__flow__content1__title">
          <!-- <br />※一部枠が埋まりつつあります。<br /> -->
          <!-- スケジュールの下に現在の受け入れ可能人数を記載しております。
          現在の受け入れ可能人数を記載しております。  -->
        </h2>
      </div>
      <div class="l-experience__main__service__flow__block">
        <splide [options]="{ rewind: true, rewindByDrag: true, arrows: false }">
          <splide-slide
            *ngFor="let questionnaire of questionnaires; index as i"
          >
            <img
              width="100%"
              height="100px"
              [src]="
                './../../../../assets/img/new/questionnaire_bubun' +
                questionnaire +
                '.png'
              "
              [alt]="'アンケート' + questionnaire"
            />
          </splide-slide>
        </splide>
      </div>
    </div>

    <div class="l-experience__main__service__schedule">
      <h3 class="l-experience__main__service__schedule__title">スケジュール</h3>
      <br />※一部枠が埋まりつつあります。<br />
      <img
        class="l-experience__main__service__schedule__img c-sp"
        src="./../../../../assets/img/klid_schedule.png"
        alt=""
      />
      <img
        class="l-experience__main__service__schedule__img c-pc"
        src="./../../../../assets/img/klid_schedule-sp.png"
        alt=""
      />
    </div>
    <!-- <div class="l-experience__main__service__scheduleTime">
      <div class="l-experience__main__service__scheduleTime__box header">
        <div>受付時間</div>
        <div>月</div>
        <div>火</div>
        <div>水</div>
        <div>木</div>
        <div>金</div>
        <div>土</div>
        <div>日</div>
      </div>
      <div class="l-experience__main__service__scheduleTime__box">
        <div>16:00～17:00</div>
        <div>×</div>
        <div>新規開校</div>
        <div>新規開校</div>
        <div>残り1名</div>
        <div>新規開校</div>
        <div>満員</div>
        <div>×</div>
      </div>
      <div class="l-experience__main__service__scheduleTime__box">
        <div>17:15～18:15</div>
        <div>×</div>
        <div>残り2名</div>
        <div>残り2名</div>
        <div>残り2名</div>
        <div>残り2名</div>
        <div>残り2名</div>
        <div>×</div>
      </div>
      <div class="l-experience__main__service__scheduleTime__box">
        <div>18:30～19:30</div>
        <div>×</div>
        <div>残り2名</div>
        <div>新規開校</div>
        <div>新規開校</div>
        <div>新規開校</div>
        <div>新規開校</div>
        <div>×</div>
      </div>
    </div> -->

    <div class="l-experience__main__price">
      <div class="l-experience__main__price__title">
        <h3>料金プラン</h3>
      </div>
      <div class="l-experience__main__price__pattern1__title">
        <h3>未就学児（年中・年長）向けプラン</h3>
      </div>
      <div class="l-experience__main__price__pattern1__main">
        <div>料金プラン</div>
        <div>料金</div>

        <div>週1回プラン</div>
        <div>6,600円（税込）</div>

        <div>週2回プラン</div>
        <div>12,800円 （税込）</div>
      </div>

      <div class="l-experience__main__price__pattern2__title">
        <h3>小学生向けプラン</h3>
      </div>
      <div class="l-experience__main__price__pattern2__main">
        <div>料金プラン</div>
        <div>料金</div>

        <div>週1回プラン</div>
        <div>7,700円（税込）</div>

        <div>週2回プラン</div>
        <div>14,400円 （税込）</div>

        <div>週3回プラン</div>
        <div>22,000円 （税込）</div>
      </div>
    </div>
    <div class="l-experience__main__service__contact-link">
      <app-contact-link></app-contact-link>
    </div>
    <!--
    p span {
      position: relative;
      background: linear-gradient(transparent 40%, yellow 40%); -->
  </div>
  <app-floating-view></app-floating-view>
  <app-footer></app-footer>
</div>
