import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './component/organisms/footer/footer.component';
import { HeaderComponent } from './component/organisms/header/header.component';
import { TopComponent } from './component/pages/top/top.component';
import { ContactComponent } from './component/pages/contact/contact.component';
import { ContactModule } from './component/pages/contact/contact.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgMessageComponent } from './component/molecules/ng-message/ng-message.component';
import { CompleteComponent } from './component/pages/complete/complete.component';
import { TitleComponent } from './component/atom/title/title.component';
import { ContactFormComponent } from './component/organisms/contact-form/contact-form.component';
import { IgxChipsModule, IgxIconModule } from 'igniteui-angular';
import { PrivacyPolicyComponent } from './component/pages/privacy-policy/privacy-policy.component';
import { CheckboxComponent } from './component/atom/checkbox/checkbox.component';
import { LoadingComponent } from './component/atom/loading/loading.component';
import { PagingSearchComponent } from './component/molecules/paging-search/paging-search.component';
import { InputComponent } from './component/atom/input/input.component';
import { TextareaComponent } from './component/atom/textarea/textarea.component';
import { SelectboxComponent } from './component/atom/selectbox/selectbox.component';
import { RadioboxComponent } from './component/atom/radiobox/radiobox.component';
import { AboutComponent } from './component/pages/about/about.component';
import { SnackbarComponent } from './component/atom/snackbar/snackbar.component';
import { RecruitComponent } from './component/pages/recruit/recruit.component';
import { FirstViewComponent } from './component/organisms/first-view/first-view.component';
import { WorriesComponent } from './component/organisms/worries/worries.component';
import { BlogListComponent } from './component/pages/blog-list/blog-list.component';
import { BlogContentComponent } from './component/pages/blog-content/blog-content.component';
import { PageingComponent } from './component/organisms/pageing/pageing.component';
import { SecondViewComponent } from './component/organisms/second-view/second-view.component';
import { ThirdViewComponent } from './component/organisms/third-view/third-view.component';
import { ServiceViewComponent } from './component/organisms/fourth-view/service-view.component';
import { FourCommitmentsViewComponent } from './component/organisms/four-commitments-view/four-commitments-view.component';
import { SeventhViewComponent } from './component/organisms/seventh-view/seventh-view.component';
import { CustomersVoiceViewComponent } from './component/organisms/customers-voice-view/customers-voice-view.component';
import { FlowViewComponent } from './component/organisms/ninth-view/flow-view.component';
import { TenthViewComponent } from './component/organisms/tenth-view/tenth-view.component';
import { QuestionViewComponent } from './component/organisms/question-view/question-view.component';
import { FloatingViewComponent } from './component/organisms/floating-view/floating-view.component';
import { FaqComponent } from './component/pages/faq/faq.component';
import { PriceComponent } from './component/pages/price/price.component';
import { TabLayoutComponent } from './component/molecules/tab-layout/tab-layout.component';
import { PricePlanComponent } from './component/organisms/price-plan/price-plan.component';
import { ContactLinkComponent } from './component/organisms/contact-link/contact-link.component';
import { ScheduleComponent } from './component/pages/schedule/schedule.component';
import { TopAdultComponent } from './component/pages/top-adult/top-adult.component';
import { AccessComponent } from './component/pages/access/access.component';
import { VoiceComponent } from './component/pages/voice/voice.component';
import { FacilityComponent } from './component/pages/facility/facility.component';
import { TopKidsComponent } from './component/pages/top-kids/top-kids.component';
import { CampaignViewComponent } from './component/organisms/campaign-view/campaign-view.component';
import { TopContentLinkComponent } from './component/molecules/top-content-link/top-content-link.component';
import { TopContentKidsComponent } from './component/organisms/top-content-kids/top-content-kids.component';
import { TopContentAdultComponent } from './component/organisms/top-content-adult/top-content-adult.component';
import { PersonalConsentFormComponent } from './component/pages/personal-consent-form/personal-consent-form.component';
import { KidsExperienceComponent } from './component/pages/kids-experience/kids-experience.component';
import { PersonalComponent } from './component/pages/personal/personal.component';
import { KidsServiceComponent } from './component/organisms/kids-service/kids-service.component';
import { SemiPersonalComponent } from './component/pages/semi-personal/semi-personal.component';
import { PreschoolerComponent } from './component/pages/preschooler/preschooler.component';
import { NgxSplideModule } from 'ngx-splide';
import { ShortLessonComponent } from './component/pages/short-lesson/short-lesson.component';
import { ShortLesson2024SummerComponent } from './component/pages/short-lesson-2024-summer/short-lesson-2024-summer.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TopComponent,
    ContactComponent,
    NgMessageComponent,
    CompleteComponent,
    TitleComponent,
    ContactFormComponent,
    PrivacyPolicyComponent,
    CheckboxComponent,
    LoadingComponent,
    PagingSearchComponent,
    InputComponent,
    TextareaComponent,
    SelectboxComponent,
    RadioboxComponent,
    AboutComponent,
    SnackbarComponent,
    RecruitComponent,
    FirstViewComponent,
    WorriesComponent,
    BlogListComponent,
    BlogContentComponent,
    PageingComponent,
    SecondViewComponent,
    ThirdViewComponent,
    ServiceViewComponent,
    FourCommitmentsViewComponent,
    SeventhViewComponent,
    CustomersVoiceViewComponent,
    FlowViewComponent,
    TenthViewComponent,
    QuestionViewComponent,
    FloatingViewComponent,
    FaqComponent,
    PriceComponent,
    TabLayoutComponent,
    PricePlanComponent,
    ContactLinkComponent,
    ScheduleComponent,
    TopAdultComponent,
    AccessComponent,
    VoiceComponent,
    FacilityComponent,
    TopKidsComponent,
    CampaignViewComponent,
    TopContentLinkComponent,
    TopContentKidsComponent,
    TopContentAdultComponent,
    PersonalConsentFormComponent,
    KidsExperienceComponent,
    PersonalComponent,
    KidsServiceComponent,
    SemiPersonalComponent,
    PreschoolerComponent,
    ShortLessonComponent,
    ShortLesson2024SummerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContactModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IgxChipsModule,
    IgxIconModule,
    NgxSplideModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
