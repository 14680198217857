import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopComponent } from './component/pages/top/top.component';
import { CompleteComponent } from './component/pages/complete/complete.component';
import { BlogListComponent } from './component/pages/blog-list/blog-list.component';
import { BlogContentComponent } from './component/pages/blog-content/blog-content.component';
import { ContactComponent } from './component/pages/contact/contact.component';
import { AboutComponent } from './component/pages/about/about.component';
import { RecruitComponent } from './component/pages/recruit/recruit.component';
import { FaqComponent } from './component/pages/faq/faq.component';
import { PriceComponent } from './component/pages/price/price.component';
import { ScheduleComponent } from './component/pages/schedule/schedule.component';
// import { TopAdultComponent } from './component/pages/top-adult/top-adult.component';
import { AccessComponent } from './component/pages/access/access.component';
import { VoiceComponent } from './component/pages/voice/voice.component';
import { FacilityComponent } from './component/pages/facility/facility.component';
import { TopKidsComponent } from './component/pages/top-kids/top-kids.component';
import { PersonalConsentFormComponent } from './component/pages/personal-consent-form/personal-consent-form.component';
import { KidsExperienceComponent } from './component/pages/kids-experience/kids-experience.component';
import { PersonalComponent } from './component/pages/personal/personal.component';
import { SemiPersonalComponent } from './component/pages/semi-personal/semi-personal.component';
import { PreschoolerComponent } from './component/pages/preschooler/preschooler.component';
import { ShortLessonComponent } from './component/pages/short-lesson/short-lesson.component';
import { ShortLesson2024SummerComponent } from './component/pages/short-lesson-2024-summer/short-lesson-2024-summer.component';

const routes: Routes = [
  { path: '', component: TopComponent },
  // { path: 'top-adult', component: TopAdultComponent },
  { path: 'top-kids', component: TopKidsComponent },
  { path: 'kids-experience', component: KidsExperienceComponent },
  { path: 'preschooler', component: PreschoolerComponent },
  { path: 'blog-list', component: BlogListComponent },
  { path: 'about', component: AboutComponent },
  { path: 'recruit', component: RecruitComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'price', component: PriceComponent },
  { path: 'access', component: AccessComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'voice', component: VoiceComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'facility', component: FacilityComponent },
  { path: 'personal-consent-form', component: PersonalConsentFormComponent },
  // { path: 'personal', component: PersonalComponent },
  { path: 'semi-personal', component: SemiPersonalComponent },
  {
    path: 'complete',
    component: CompleteComponent,
    data: { animationState: 'detailPage' },
  },
  { path: 'blog-content/:id', component: BlogContentComponent },
  { path: 'short-lesson', component: ShortLessonComponent },
  {
    path: 'short-lesson-2024-summer',
    component: ShortLesson2024SummerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
